import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Card = styled.article`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
`

const CardImage = styled.div`
  aspect-ratio: 16/9;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.8)
  ), url(${props => props.src});
  background-size: cover;
  background-position: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Title = styled.h2`
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
`

const Date = styled.time`
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
`

const ProjectDisplay = ({
  title,
  date,
  link,
  imageSrc = "https://images.unsplash.com/photo-1513569771920-c9e1d31714af?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60",
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card>
        <CardImage src={imageSrc}>
          <Title>{title}</Title>
          <Date>{date}</Date>
        </CardImage>
      </Card>
    </Link>
  )
}

export default ProjectDisplay
