import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectDisplay from "../components/projectDisplay"
import styled from "@emotion/styled"

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const portfolioPosts = posts.filter(
    post => post.fields.slug.split("/")[1] == "portfolio"
  )

  if (portfolioPosts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Projects" />
        <Content>
          <p>No projects found.</p>
        </Content>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Projects" />
      <Content>
        <ProjectGrid>
          {portfolioPosts.map(post => {
            return (
              <ProjectDisplay
                key={post.fields.slug}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                link={post.fields.slug}
                imageSrc={post.frontmatter.imageSrc || undefined}
              />
            )
          })}
        </ProjectGrid>
      </Content>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imageSrc
        }
      }
    }
  }
`
